import React from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  max-width: 400px;
  width: 320px;
  text-align: center;
`;

const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  padding: 24px 0;
`;

const ModalText = styled.div`
 margin-bottom: 20px;
`;

const ModalButton = styled.button`
  background-color: #333;
  color: #fff;
  padding: 17px 20px;
  border: none;
  border-radius: 0 0 8px 8px;;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
`;
const Modal = ({ isOpen, onClose, title, text, sub, confirm }) => {
  return isOpen ? (
    <>
      <ModalContainer />
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        {text &&<ModalText> {text} {sub && <br />}{sub}</ModalText> }
        <ModalButton onClick={onClose}>{confirm}</ModalButton>
      </ModalContent>
    </>
  ) : null;
};

export default Modal;