import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HelmetMeta from "../util/helmet";
import Coupon from "./coupon";


const searchParams = new URLSearchParams(window.location.search);
const idParam = searchParams.get("userIdCode");
const langParam = searchParams.get("lang");
const freeParam = searchParams.get("isFree");

const TabImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TabButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TabButton = styled.button`
  background-color: ${(props) => props.color};
  color: #efefef;
  border: none;
  height: 50px;
  width: ${(props) => (props.show ? "calc(100%/3)" : "calc(100%/2)")};
  padding: 10px 10px;
  // font-size: 12px;
  font-size: 0.8rem;
  cursor: pointer;
  filter: brightness(${(props) => (props.active ? "100%" : "60%")});
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  box-shadow: ${(props) => (props.active ? "5px 0px 4px rgba(0, 0, 0, 0.25), -5px 0px 4px rgba(0, 0, 0, 0.25)" : "")};
  z-index:${(props) => (props.active ? "10" : "0")};
`;

const TabContent = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  width: 100%;
`;

const ContentImg = styled.img`
  width: 100%;
  box-sizing: border-box;
  vertical-align: middle;
`;

const ButtonContainer = styled.div`
  display: flex;
  background: #ffcb38;
`;

const DownloadBox = styled.div`
   position: relative;
`;
const DownloadKrButton = styled.button`
  background: #393938;
  border-radius: 100px;
  width: 139px;
  height: 48px;
  border: none;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  left: 50%;
  top: 66%;
  transform: translate(-50%, 50%);
  position: absolute;

  :active {
    filter: brightness(90%);
  }

`;

const DownloadEnButton = styled.button`
  background: #393938;
  border-radius: 100px;
  width: 185px;
  height: 48px;
  border: none;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  left: 50%;
  //top: 69%;
  top:  ${(props) => (props.lang ? " 69%" : "62%")};
  transform: translate(-50%, 50%);
  position: absolute;

  :active {
    filter: brightness(50%);
  }
`;

const Manual = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [show, setShow] = useState(false);
  useEffect(() => {
    if (idParam) {
      setShow(true);
      if (freeParam == 1){
        setShow(false);
      }
    }
  }, []);

  const [lang, setLang] = useState("ko");
  useEffect(() => {
    if (langParam === "ko") {
      setLang("ko");
    } else if (langParam === "en") {
      setLang("en");
    } else if (langParam === "ja") {
      setLang("ja");
    }
  }, []);

  const handlClick = (event) => {
    window.location.href = 'https://link-inmind.azurewebsites.net/anysign';
  };

  return (
    <TabImagesContainer>
      {lang === "ko" && (
        <TabButtonContainer>
          <TabButton color="#FFCF3D" active={activeTab === 0} onClick={() => handleTabClick(0)}>
            서비스 소개
          </TabButton>
          <TabButton color="#2E7EFF" active={activeTab === 1} onClick={() => handleTabClick(1)}>
            스트레스 측정
          </TabButton>
          {show && (
            <TabButton color="#E06C2C" active={activeTab === 2} onClick={() => handleTabClick(2)}>
              쿠폰 등록
            </TabButton>
          )}
        </TabButtonContainer>
      )}
      {lang === "en" && (
        <TabButtonContainer>
          <TabButton color="#FFCF3D" active={activeTab === 0} onClick={() => handleTabClick(0)}>
            Introduction Services
          </TabButton>
          <TabButton color="#2E7EFF" active={activeTab === 1} onClick={() => handleTabClick(1)}>
            Stress measurement
          </TabButton>
          {show && (
            <TabButton color="#E06C2C" active={activeTab === 2} onClick={() => handleTabClick(2)}>
              Coupon registration
            </TabButton>
          )}
        </TabButtonContainer>
      )}
      {lang === "ja" && (
        <TabButtonContainer>
          <TabButton color="#FFCF3D" active={activeTab === 0} onClick={() => handleTabClick(0)}>
            サービス紹介
          </TabButton>
          <TabButton color="#2E7EFF" active={activeTab === 1} onClick={() => handleTabClick(1)}>
            ストレス測定
          </TabButton>
          {show && (
            <TabButton color="#E06C2C" active={activeTab === 2} onClick={() => handleTabClick(2)}>
              クーポン登録
            </TabButton>
          )}
        </TabButtonContainer>
      )}
      <TabContent active={activeTab === 0}>
        {lang === "ko" && (
          <div>
            <DownloadBox>
              <ContentImg src="./서비스 소개_1.jpg" />
              <DownloadKrButton onClick={handlClick}>앱 다운로드</DownloadKrButton>
            </DownloadBox>
            <ContentImg src="./서비스 소개_2.jpg" />
            <ContentImg src="./서비스 소개_3.jpg" />
            <ContentImg src="./서비스 소개_4.jpg" />
            <ContentImg src="./서비스 소개_5.jpg" />
            <ContentImg src="./서비스 소개_6.jpg" />
            <ContentImg src="./서비스 소개_7.jpg" />
            <ContentImg src="./서비스 소개_8.jpg" />
          </div>
        )}
        {lang === "en" && (
          <div>
            <DownloadBox>
              <ContentImg src="./서비스 소개_eng_1.jpg" />
              <ButtonContainer>
                <DownloadEnButton onClick={handlClick} lang='en'>App Download</DownloadEnButton>
              </ButtonContainer>
            </DownloadBox>
            <ContentImg src="./서비스 소개_eng_2.jpg" />
            <ContentImg src="./서비스 소개_eng_3.jpg" />
            <ContentImg src="./서비스 소개_eng_4.jpg" />
            <ContentImg src="./서비스 소개_eng_5.jpg" />
            <ContentImg src="./서비스 소개_eng_6.jpg" />
            <ContentImg src="./서비스 소개_eng_7.jpg" />
            <ContentImg src="./서비스 소개_eng_8.jpg" />
          </div>
        )}
        {lang === "ja" && (
          <div>
            <DownloadBox>
              <ContentImg src="./서비스 소개_jp_1.jpg" />
              <ButtonContainer>
                <DownloadEnButton onClick={handlClick} >App Download</DownloadEnButton>
              </ButtonContainer>
            </DownloadBox>
            <ContentImg src="./서비스 소개_jp_2.jpg" />
            <ContentImg src="./서비스 소개_jp_3.jpg" />
            <ContentImg src="./서비스 소개_jp_4.jpg" />
            <ContentImg src="./서비스 소개_jp_5.jpg" />
            <ContentImg src="./서비스 소개_jp_6.jpg" />
            <ContentImg src="./서비스 소개_jp_7.jpg" />
            <ContentImg src="./서비스 소개_jp_8.jpg" />
          </div>
        )}
      </TabContent>
      <TabContent active={activeTab === 1}>
        {lang === "ko" && (
          <>
            <HelmetMeta title="체계적인 마음 건강 관리 in mind" />
            <div>
              <ContentImg src="./PPG_1.jpg" />
              <ContentImg src="./PPG_2.jpg" />
              <ContentImg src="./PPG_3.jpg" />
              <ContentImg src="./PPG_4.jpg" />
              <ContentImg src="./PPG_5.jpg" />
              <ContentImg src="./PPG_6.jpg" />
              <ContentImg src="./PPG_7.jpg" />
            </div>
          </>
        )}
        {lang === "en" && (
          <>
          <HelmetMeta title="in mind" />
          <div>
            <ContentImg src="./PPG_eng_1.jpg" />
            <ContentImg src="./PPG_eng_2.jpg" />
            <ContentImg src="./PPG_eng_3.jpg" />
            <ContentImg src="./PPG_eng_4.jpg" />
            <ContentImg src="./PPG_eng_5.jpg" />
            <ContentImg src="./PPG_eng_6.jpg" />
            <ContentImg src="./PPG_eng_7.jpg" />
          </div>
          </>
        )}
        {lang === "ja" && (
          <>
            <HelmetMeta title="in mind" />
            <div>
              <ContentImg src="./PPG_jp_1.jpg" />
              <ContentImg src="./PPG_jp_2.jpg" />
              <ContentImg src="./PPG_jp_3.jpg" />
              <ContentImg src="./PPG_jp_4.jpg" />
              <ContentImg src="./PPG_jp_5.jpg" />
              <ContentImg src="./PPG_jp_6.jpg" />
              <ContentImg src="./PPG_jp_7.jpg" />
            </div>
          </>
        )}
      </TabContent>
      {/* if use router, router seems need to be implemented on tabs too.. but that would brings reload..? */}
        <TabContent active={activeTab === 2}>
          <Coupon />
        </TabContent>
      
    </TabImagesContainer>
  );
};

export default Manual;
