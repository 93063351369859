import React from 'react'
import styled from "styled-components";

const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px;

`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 20px;
  margin-bottom: 16px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 14px;
`;


function Policy() {
  return (
    <PolicyContainer>
        <Title>
        in mind 기프트 카드 이용 약관
        </Title>
        <Contents>

        마지막 업데이트 2023년 3월 10일
        <br></br><br></br>
        본 기프트 카드 약관(“약관”)은 in mind 기프트 카드의 구매 또는 사용에 적용되며 in mind 서비스를 사용하는 동안에는 추가로 서비스 약관 및 개인 정보 보호 정책이 적용됩니다. 본문에서 사용되었지만 정의되지 않은 모든 용어는 서비스 약관 및 개인 정보 보호 정책에 명시된 의미를 갖습니다.기프트 카드를 구매, 획득 또는 교환함으로써 귀하는 본 약관에 동의하는 것으로 간주됩니다.
        <br></br><br></br>
        기프트 카드 또는 본 약관에 대해 질문이 있는 경우 아래 연락처를 통해 당사에 문의하십시오.
        <br></br><br></br>
        [정의]<br></br>
        in mind 기프트 카드(이하 “기프트 카드”): (주)디맨드 (이하 “회사”)에서 판매하는 무기명 선불식 전자상품권으로서 in mind 내 쿠폰 등록 링크를 통해 등록하여 in mind 서비스 사용권을 획득할 수 있는 수단을 의미합니다.
        <br></br><br></br>
        구매자: 회사에서 발행한 기프트 카드를 구매하는 고객을 의미합니다.
        <br></br><br></br>
        사용자: 구매자로서 기프트 카드를 직접 구매하거나 양도받은 기프트 카드를 직접 사용하는 고객을 의미합니다.
        <br></br><br></br>
        <br></br>[구매, 교환 및 적격 구독]
        <br></br>in mind의 사용권을 획득하기 위한 기프트 카드는 온라인 또는 공인된 제 3자 판매점에서 구입할 수 있습니다. 기프트 카드는 기프트 카드에 표시된 대로 1년의 구독 기간을 제공하고 있습니다.
        <br></br>기프트 카드를 사용하고자 할 경우 in mind 기프트 카드 뒷면의 쿠폰 코드 등록 방법 절차에 따라 쿠폰 코드 등록을 진행하여 주십시오. in mind의 쿠폰 코드 등록 절차에 따라 서비스 사용권한이 활성화되며, 귀하의 in mind 계정 생성시 서비스 약관 및 개인정보 보호 정책이 적용됩니다.
        <br></br>[현금 환불, 교환 또는 재판매 금지]
        <br></br>기프트 카드는 해당 법률에서 달리 요구하지 않는 한 사용 전후에 환불하거나 현금으로 교환할 수 없으며 판매세가 부과될 수 있습니다. 기프트 카드를 사용(등록)한 후에는 사용권한을 다른 사람이나 계정에 양도하거나 공유할 수 없습니다.
        <br></br>기프트 카드는 재판매할 수 없으며 재판매된 모든 기프트 카드는 회사의 단독 재량에 따라 무효화될 수 있습니다.
        <br></br>[만료 및 손실 위험]
        <br></br>별도 명시된 내용이 있지 않은 한 기프트 카드는 만료되지 않습니다. 기프트 카드와 쿠폰 코드를 안전하게 보관하십시오. 회사는 기프트 카드의 분실, 도난 또는 무단 사용에 대해 책임을 지지 않습니다.
        <br></br>[제한]
        <br></br>회사의 사전 서면 동의 없이 광고, 마케팅 또는 판촉과 관련되거나 제품 또는 서비스가 in mind와 연관, 후원된다는 것을 암시하는 방식으로 기프트 카드를 사용하는 것은 명시적으로 금지됩니다.
        <br></br>[책임 소재]
        <br></br>회사의 관리 소홀로 인한 데이터 위조 또는 변조 시 그에 대한 책임은 회사에 있습니다. 이에 대해 회사는 해당 사용자에게 피해금액과 동일한 현금 또는 기프트 카드로 배상합니다.
        다만, 사용자의 관리 소홀로 인한 분실 및 도용에 관한 책임은 구매자 및 사용자에 있습니다.
        <br></br>[본 약관의 수정]
        <br></br>회사는 단독 재량에 따라 언제든지 본 약관을 수정, 또는 포기할 권리를 보유합니다. 본 약관의 최신 버전은 이 웹사이트에 표시되며 본 약관의 모든 이전 버전을 대체합니다.
        <br></br>[홈페이지 및 문의]
        <br></br>홈페이지: http://demand.co.kr
        <br></br>문의사항: help@demand.co.kr
        </Contents>


    </PolicyContainer>
  )
}

export default Policy