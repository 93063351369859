import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Modal from '../components/Modal';
import { Link } from 'react-router-dom';

const Container = styled.div`
   width: 330px;
   margin: auto;
`;
const TextContainer = styled.div`
   margin-top: 50px;
`;

const BoldTitle = styled.div`
   width: 100%;
   margin: 0 0 16px 0;
   font-weight: bold;
   font-size: 30px;
`;

const Text = styled.div`
   width: 100%;
   font-weight: ${(props) => props.weight};
   font-size: 15px;
`;

const CouponContainer = styled.div`
   width: 100%;
   margin: 32px 0 0;
   display: flex;
   justify-content: space-between;
`;

const NumberInput = styled.input`
   height: 40px;
   width: 75px;
   border-radius: 8px;
   border: 2px solid #e0d8d2;
   box-sizing: border-box;
   text-align: center;
`;
const SubmitButton = styled.button`
   background: #e0d8d2;
   border-radius: 100px;
   padding: 12px 24px;
   border: none;
   font-size: 15px;
   color: #393938;
   font-weight: bold;
   margin: 32px auto 16px;
   :active {
      filter: brightness(50%);
   }
`;

const ButtonContainer = styled.div`
   display: flex;
`;

const PolictLink = styled.div`
   font-size: 15px;
   font-weight: 400;
   line-height: 22px;
   letter-spacing: -0.006em;
   text-align: left;
`;


function Coupon() {
   const [inputValues, setInputValues] = useState(['', '', '', '']);
   const submitButtonRef = useRef(null);
   const linkstyle  = {
      fontWeight: 'bold'
   }
   const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];

   const handleInputChange = (event, index) => {
      const value = event.target.value;


      if (/^\d*$/.test(value) && value.length <= 4) {
         setInputValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = value;
            return newValues;
         });
 
         if (value.length === 4 && index < 3 ) {
            refs[index + 1].current.focus();
            refs[index].current.style.borderColor = '#1E1E1E';
         } else if(value.length === 4 && index === 3){
            refs[index].current.style.borderColor = '#1E1E1E';
         }else {
            refs[index].current.style.borderColor = '#E0D8D2';
         }
        
      } else {
         setIsCharOpen(true);
         //alert('숫자를 입력해주세요.');
      }
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      const code = inputValues.join('');
      if (code.length !== 16) {
         setIsNumberOpen(true);
         //alert('16자리 숫자를 입력해주세요.');
         return false
      }
      axios
      .post('https://b.inmind-manual.com/coupon/activation', { code, userIdCode })
      //.post('https://app.demand.co.kr/manual/api/coupon/activation', { code, userIdCode })
         .then((response) => {
            console.log(response.data);
            if (response.data === 'success') {
               setIsConfirmOpen(true);
               //alert('쿠폰이 등록되었습니다.');
            } else if (response.data === 'c1') {
               setIsWrongOpen(true);
               //alert('잘못된 코드번호예요.');
            } else if (response.data === 'c2') {
               setIsUsedOpen(true);
               //alert('이미 등록된 코드예요.');
            } else if (response.data === 'c3') {
               setIsInvalidOpen(true);
               //alert('사용 불가처리된 코드예요');
            }else if (response.data === 'c4') {
               setIsExpiredOpen(true);
               //alert('이미 만료된 코드예요.');
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const searchParams = new URLSearchParams(window.location.search);

   const param = searchParams.get('userIdCode');
   //7zNVk7fXIFVZtQ++QB/CWw==
   //const searchParams = new URLSearchParams("?userIdCode=N3pOVms3ZlhJRlZadFErK1FCL0NXdz09");
   //N3pOVms3ZlhJRlZadFErK1FCL0NXdz09
   const userIdCode = window.atob(param);

   const langParam = searchParams.get('lang');

   const [lang, setLang] = useState('ko');
   useEffect(() => {
      if (langParam === 'ko') {
         setLang('ko');
      } else if (langParam === 'en') {
         setLang('en');
      } else if (langParam === 'ja') {
         setLang('ja');
      }
   }, []);

   const [isCharModalOpen, setIsCharOpen] = useState(false);
   const [isWrongModalOpen, setIsWrongOpen] = useState(false);
   const [isConfirmModalOpen, setIsConfirmOpen] = useState(false);
   const [isUsedModalOpen, setIsUsedOpen] = useState(false);
   const [isExpiredModalOpen, setIsExpiredOpen] = useState(false);
   const [isNumberModalOpen, setIsNumberOpen] = useState(false);
   const [isInvalidModalOpen, setIsInvalidOpen] = useState(false);

   const handleCloseModal = () => {
      setIsCharOpen(false);
      setIsWrongOpen(false);
      setIsConfirmOpen(false);
      setIsUsedOpen(false);
      setIsExpiredOpen(false);
      setIsInvalidOpen(false);
      setIsNumberOpen(false);
   };
   useEffect(() => {
      const btn = document.querySelector('.btn_active');
      if (inputValues.join('').length === 16) {
        btn.style.background = '#393938';
        btn.style.color = "#fff"
      } else {
        btn.style.background = '#E0D8D2';
        btn.style.color = "#393938"
      }
    }, [inputValues]);


   return (
      <div>
         {lang === 'ko' && (
            <Container>
               <TextContainer>
                  <BoldTitle>쿠폰 코드를 입력해주세요.</BoldTitle>
                  <Text weight="normal">쿠폰 등록을 완료한 후 사용자 권한 갱신을 위해</Text>
                  <Text weight="bold">반드시 앱을 종료하고 재실행해주세요.</Text>
               </TextContainer>
               <form onSubmit={handleSubmit}>
                  <CouponContainer>
                     <NumberInput type="tel" maxLength={4} value={inputValues[0]} onChange={(e) => handleInputChange(e, 0)} ref={refs[0]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[1]} onChange={(e) => handleInputChange(e, 1)} ref={refs[1]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[2]} onChange={(e) => handleInputChange(e, 2)} ref={refs[2]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[3]} onChange={(e) => handleInputChange(e, 3)} ref={refs[3]} />
                  </CouponContainer>
                  <ButtonContainer>
                     <SubmitButton ref={submitButtonRef} onClick={handleSubmit} className="btn_active">
                        쿠폰 등록
                     </SubmitButton>
                  </ButtonContainer>
                  <PolictLink>
                  in mind 쿠폰을 구매, 등록하면 <Link to='/policy' style = {linkstyle}>기프트 카드 이용 약관</Link>에 동의하는 것으로 간주됩니다.
                  </PolictLink>
               </form>
            </Container>
         )}
         {lang === 'en' && (
            <Container>
               <TextContainer>
                  <BoldTitle>Please enter coupon code.</BoldTitle>
                  <Text weight="normal">After completing coupon registration, </Text>
                  <Text weight="bold">be sure to relaunch the app to activate membership access.</Text>
               </TextContainer>
               <form onSubmit={handleSubmit}>
                  <CouponContainer>
                     <NumberInput type="tel" maxLength={4} value={inputValues[0]} onChange={(e) => handleInputChange(e, 0)} ref={refs[0]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[1]} onChange={(e) => handleInputChange(e, 1)} ref={refs[1]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[2]} onChange={(e) => handleInputChange(e, 2)} ref={refs[2]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[3]} onChange={(e) => handleInputChange(e, 3)} ref={refs[3]} />
                  </CouponContainer>
                  <ButtonContainer>
                     <SubmitButton ref={submitButtonRef} onClick={handleSubmit} className="btn_active">
                     registration
                     </SubmitButton>
                  </ButtonContainer>
               </form>
            </Container>
         )}
         {lang === 'ja' && (
            <Container>
               <TextContainer>
                  <BoldTitle>クーポンコードを入力してください。</BoldTitle>
                  <Text weight="normal">クーポン登録完了後、 </Text>
                  <Text weight="bold">必ずアプリを再起動して会員アクセスを有効にしてください。</Text>
               </TextContainer>
               <form onSubmit={handleSubmit}>
                  <CouponContainer>
                     <NumberInput type="tel" maxLength={4} value={inputValues[0]} onChange={(e) => handleInputChange(e, 0)} ref={refs[0]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[1]} onChange={(e) => handleInputChange(e, 1)} ref={refs[1]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[2]} onChange={(e) => handleInputChange(e, 2)} ref={refs[2]} />
                     <NumberInput type="tel" maxLength={4} value={inputValues[3]} onChange={(e) => handleInputChange(e, 3)} ref={refs[3]} />
                  </CouponContainer>
                  <ButtonContainer>
                     <SubmitButton ref={submitButtonRef} onClick={handleSubmit} className="btn_active">
                     登録
                     </SubmitButton>
                  </ButtonContainer>
               </form>
            </Container>
         )}
         {/* {lang === 'en' && (
            <Container>
               <TextContainer>
                  <BoldTitle> We are sorry! </BoldTitle>
                  <Text weight="normal">This page is not available in your country.</Text>
               </TextContainer>
            </Container>
         )}
         {lang === 'ja' && (
            <Container>
               <TextContainer>
                  <BoldTitle> We are sorry! </BoldTitle>
                  <Text weight="normal">該当国がサポートしていない機能です。</Text>
               </TextContainer>
            </Container>
         )} */}
          {lang === 'ko' && (
            <div>
               <Modal isOpen={isCharModalOpen} onClose={handleCloseModal} title="숫자를 입력해주세요." confirm ="확인"/>
               <Modal isOpen={isConfirmModalOpen} onClose={handleCloseModal} title="쿠폰 등록이 완료되었어요." text="앱을 완전히 종료한 후" sub="다시 접속해 주세요."confirm ="확인" />
               <Modal isOpen={isWrongModalOpen} onClose={handleCloseModal} title="잘못된 코드번호예요." confirm ="확인" />
               <Modal isOpen={isUsedModalOpen} onClose={handleCloseModal} title="이미 등록된 코드예요."confirm ="확인" />
               <Modal isOpen={isInvalidModalOpen} onClose={handleCloseModal} title="사용 불가 처리된 코드예요." confirm ="확인"/>
               <Modal isOpen={isExpiredModalOpen} onClose={handleCloseModal} title="이미 만료된 코드예요."confirm ="확인" />
               <Modal isOpen={isNumberModalOpen} onClose={handleCloseModal} title="16자리 숫자를 입력해주세요."confirm ="확인" />
            </div>
         )}
         {lang === 'en' && (
            <div>
               <Modal isOpen={isCharModalOpen} onClose={handleCloseModal} title="Please enter numbers only." confirm ="Ok"/>
               <Modal isOpen={isConfirmModalOpen} onClose={handleCloseModal} title="Coupon registration is completed." text="Please restart the app" confirm ="Ok"/>
               <Modal isOpen={isWrongModalOpen} onClose={handleCloseModal} title="Please check code number again."  confirm ="Ok"/>
               <Modal isOpen={isUsedModalOpen} onClose={handleCloseModal} title="This code has already been registered." confirm ="Ok"/>
               <Modal isOpen={isInvalidModalOpen} onClose={handleCloseModal} title="This code is invalid" confirm ="Ok"/>
               <Modal isOpen={isExpiredModalOpen} onClose={handleCloseModal} title="This code has been expired." confirm ="Ok"/>
               <Modal isOpen={isNumberModalOpen} onClose={handleCloseModal} title="Please enter a 16-digit number." confirm ="Ok"/>
            </div>
         )}
         {lang === 'ja' && (
            <div>
               <Modal isOpen={isCharModalOpen} onClose={handleCloseModal} title="数字を入力してください。" confirm="はい"/>
               <Modal isOpen={isConfirmModalOpen} onClose={handleCloseModal} title="クーポン登録が完了しました。" text="アプリを再接続してください。" confirm="はい"/>
               <Modal isOpen={isWrongModalOpen} onClose={handleCloseModal} title="無効なコード番号です。" confirm="はい" />
               <Modal isOpen={isUsedModalOpen} onClose={handleCloseModal} title="すでに登録されているコードです。" confirm="はい"/>
               <Modal isOpen={isInvalidModalOpen} onClose={handleCloseModal} title="使用不可のコードです。" confirm="はい"/>
               <Modal isOpen={isExpiredModalOpen} onClose={handleCloseModal} title="すでに期限切れのコードです。" confirm="はい"/>
               <Modal isOpen={isNumberModalOpen} onClose={handleCloseModal} title="16桁の数字を入力してください。" confirm="はい"/>
            </div>
         )}

         
      </div>
   );
}

export default Coupon;