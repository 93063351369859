import React from "react";
import Manual from "./pages/maunual";
import Policy from "./pages/policy";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Manual />}></Route>
            <Route path="/policy" element={<Policy />}></Route>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
